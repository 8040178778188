.pageBackground {
    background-color: red !important;
}

.HeadingStyle {
    position: absolute;
    width: 135px;
    height: 26px;
    left: 40px;
    top: 32px;

}

.label1Style {
    font-family: "SF Pro Display Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
}

.mainHeadingStyle {
    position: absolute;
    width: 404px;
    height: 96px;
    left: 40px;
    top: 178px;
}

.label2Style {
    font-family: "SF Pro Display Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 47px;
    letter-spacing: 1px;
    color: #000000;
}

/* Media Query for mobile devices (screen width up to 600px) */
@media (max-width: 600px) {
    .label2Style {
        font-family: "SF Pro Display Regular";
        /* font-style: normal; */
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 28px;
        letter-spacing: 1px;
        color: #000000;
    }
}

.subHeadingStyle {
    position: absolute;
    width: 603px;
    height: 52px;
    left: 40px;
    top: 298px;
}

.label3Style {
    font-family: "SF Pro Text Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #888888;
}

/* Media Query for mobile devices (screen width up to 600px) */
@media (max-width: 600px) {
    .label3Style {
        font-family: "SF Pro Text Regular";
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #888888;
    }
}

.nameStyle {
    color: #888888;
    font-weight: 600;
    /* text-decoration: underline; */
    cursor: pointer;

}

.name2Style {
    color: #888888;
    font-weight: 600;
    /* text-decoration: underline; */
    cursor: pointer;
}

.question_mark {
    color: #0085FF;
    margin-left: 5px;
}

.textInfo_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text_info {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: #333333;
}

.profile_outer {
    height: 70px;
    width: 82px;
    background-color: #fff;
    border-radius: 50%;
}

.main_wrapper {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 0 70px;
}

.profile_info {
    display: flex;
    flex-direction: column;
}

.firstLable {
    font-family: 'SF Pro Display';
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}

.secondLable {
    font-family: 'SF Pro Display';
    font-weight: 400;
    font-size: 16px;
    color: #888888;
}

.rec-pagination-dot {
    background-color: #999;
}

.rec-pagination-dot.rec-pagination-dot-active {
    background-color: #18A457;
}

button.sc-gKXOVf.krmNah.rec.rec-dot.rec.rec-dot_active {
    border: none;
    /* box-shadow: none; */
    background-color: grey;
    height: 8px;
    width: 8px;
}

button.sc-gKXOVf.jhmYzC.rec.rec-dot {
    height: 8px;
    width: 8px;
}

.Chip_title {
    font-family: 'SF Pro Text';
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.hHJTFD {
    margin: 0 !important;
}


.car_parent .rec-item-wrapper,
.car_parent .rec .rec-slider {
    height: 100% !important;
}

.car_parent .rec .rec-pagination {
    position: absolute;
    /* bottom: 3%; */
    bottom: 10px;
}

.invite_profile {
    height: 40px;
    width: 40px !important;
    background-color: #EEEEEE;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 20px;
}

.invite_profile_signUp {
    height: 40px;
    width: 40px !important;
    background-color: #EEEEEE;
    border-radius: 50%;
    margin-right: 5px;
}

.react-multiple-select-close-icon {
    color: #50676A;
    /* change the color to your desired color */
}

.car_parent .rec-item-wrapper div:first-child {
    width: 100%;
}

.car_parent .rec-item-wrapper .carousel-item {
    overflow: auto;
    overflow-x: hidden;
}

.custom-multiselect .close-icon {
    color: black;
}

.multiselect-container .icon_cancel {
    fill: red !important;
}

.password-strength-bar .score-0 {
    display: none;
}

.password-strength-bar-wrapper>*:last-child {
    display: none;
}

/* Complete Sign UP */
.signUpCompleteWrapper {
    padding: 25px;
    border-radius: 4px;
    border: 2px solid #80808031;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.completeTitle {
    font-size: 20px;
    font-weight: 700;
}

.downloadTitle {
    color: #888888;
    font-size: 16px;
    font-weight: 600;
}

.appouter {
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    margin-top: 25px;
}

.appBtn,
.completebtn {
    background: #18A457;
    box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    padding: 12px 10px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    font-size: 16px;
    line-height: 19px;
}

.completebtn {
    width: 155px;
    margin-left: auto;
}

.appBtn img {
    height: 24px;
    width: 24px;
}

.appBtn:first-child {
    width: 94px;
}

.appBtn:last-child {
    width: 120px;
}

.appWraper {
    height: calc(100vh - 300px);
}

.next_btn {
    bottom: 6px !important;
}

/* ***** MEDIA QUERY ***** */
@media (max-width: 575px) {
    .rec .rec-pagination {
        bottom: 0%;
    }


    .mainWrapper {
        margin-top: 30px;
    }
}

/* CSS for hiding the specified Grid in mobile view */
@media (max-width: 1180px) {
    .mainWrapper {
        display: none;
    }
}

.signUpHead {
    padding: 20px 20px 24px;
    /* Default padding for all screen sizes */
}

/* Media Query for mobile devices (screen width up to 600px) */
@media (max-width: 600px) {
    .signUpHead {
        padding: 20px;
        /* Change the padding for mobile devices */
    }
}

.car_parent .carousel-item {
    height: 90vh !important;
    overflow-y: auto !important;
    box-shadow: 7px 3px 10px 9px #00000014;
}

.rec-carousel-wrapper {
    height: 98vh !important;
    position: relative !important;
}

.rec-carousel-wrapper .rec-carousel {
    height: 98vh !important;
    position: relative !important;
}

@media (max-width: 575px) {
    .rec-carousel-wrapper {
        height: 90vh !important;
    }

    .rec-carousel-wrapper .rec-carousel {
        height: 90vh !important;
    }
}